@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

@font-face {
	font-family: 'Abel';
	src: url('/public/fonts/Abel.ttf');
}

html {
	font-family: 'Abel';
}

@layer base {
	html {
		font-family: 'Abel';
	}
}

#components-layout-demo-fixed-sider .logo {
	height: 32px;
	margin: 16px;
	background: #243677;
}
.site-layout .site-layout-background {
	background: #243677;
}

.site-description-item-profile-wrapper {
	margin-bottom: 7px;
	color: #243677;
	font-size: 14px;
	line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
	display: block;
	margin-bottom: 16px;
	color: #243677;
	font-size: 16px;
	line-height: 1.5715;
}

.site-description-item-profile-p-label {
	display: inline-block;
	margin-right: 8px;
	color: #243677;
}

.ql-container {
	height: auto;
	min-height: 200px;
	font: 'Abel';
	overflow-y: scroll;
}

@media only screen and (min-width: 240px) and (max-width: 767px) {
	/* sidebar float on mobile  */
	:where(.css-dev-only-do-not-override-mxhywb).ant-layout .ant-layout-sider {
		position: absolute;
		z-index: 10;
	}
}
